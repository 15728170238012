import { useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BiSignal5, BiNoSignal } from 'react-icons/bi';

export default function ConnectionStatus() {
  const { t } = useTranslation();

  type NetStatusHistory = string;

  const [netStatus, setNetStatus] = useState<NetStatusHistory>('online');

  function handleConnectionChange() {
    const status = navigator.onLine ? 'online' : 'offline';
    setNetStatus(status);
  }

  window.addEventListener('online', () => handleConnectionChange());
  window.addEventListener('offline', () => handleConnectionChange());

  return (
    <Box
      margin="0 8px"
      title={t('layout.statusConnection')}
    >
      {netStatus === 'online' ? (
        <BiSignal5
          size="1.2em"
          color="#5daa61"
        />
      ) : (
        <BiNoSignal
          size="1.2em"
          color="#de3d32"
        />
      )}
    </Box>
  );
}
