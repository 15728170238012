import { useState, useRef } from 'react';
import { toast } from 'react-toastify';

const useDoTheBackup = (pivotal, endpoints, downloadData) => {
  const [started, setStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const finalData = useRef([]);
  const [error, setError] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);
  const [projectProgress, setProjectProgress] = useState(0);

  const doTheBackup = async () => {
    setStarted(true);
    setIsLoading(true);
    setError(null);

    const projectsData = [];

    try {
      const resProjects = await pivotal.get(endpoints.get.allProjects);
      const totalProjects = resProjects.data.length;

      for (let i = 0; i < totalProjects; i++) {
        const project = resProjects.data[i];
        setCurrentProject(project.name);

        const projectData = {
          id: project.id,
          name: project.name,
          stories: [],
        };

        const resStories = await pivotal.get(
          endpoints.get.allStoriesProject(project.id)
        );

        for (let j = 0; j < resStories.data.length; j++) {
          const story = resStories.data[j];

          await new Promise((resolve) => setTimeout(resolve, 1000));

          try {
            const resStory = await pivotal.get(
              endpoints.get.singleStory(project.id, story.id)
            );

            const storyData = {
              ...resStory.data,
              comments: [],
              activity: [],
            };

            const resComments = await pivotal.get(
              endpoints.get.commentsStory(project.id, story.id)
            );
            storyData.comments = resComments.data;

            const resActivity = await pivotal.get(
              endpoints.get.activityStory(project.id, story.id)
            );
            storyData.activity = resActivity.data;

            projectData.stories.push(storyData);
          } catch (err) {
            setError(
              `Erro ao coletar dados da história [${err.response.status}]`
            );
            projectData.stories.push({
              id: story.id,
              name: story.name,
              error: 'Falha ao coletar dados da história',
            });
          }
        }

        projectsData.push(projectData);

        const progress = Math.round(((i + 1) / totalProjects) * 100);
        setProjectProgress(progress);
      }

      finalData.current = projectsData;
    } catch (err) {
      toast.error(
        `Erro ao coletar os dados do Pivotal Tracker. [Erro: ${err.response?.status}]`,
        { toastId: 'backerror' }
      );

      if (projectsData.length > 0) {
        setError(
          `Ocorreu um erro durante o processo. Os dados coletados até o momento foram exportados. [Erro: ${err.response?.status}]`
        );
      } else {
        setError(
          `Falha ao coletar os dados do Pivotal Tracker. [Erro: ${err.response?.status}]`
        );
      }
    } finally {
      setIsLoading(false);
      downloadData(finalData.current);

      if (finalData.current.length === 0) {
        toast.info('JSON vazio', { toastId: 'emptyfile' });
      }
    }
  };

  const tryAgain = () => {
    setStarted(false);
    finalData.current = [];
    setIsLoading(false);
    setError(null);
    setProjectProgress(0);
    setCurrentProject(null);
    doTheBackup();
  };

  return {
    started,
    isLoading,
    finalData,
    error,
    currentProject,
    projectProgress,
    doTheBackup,
    tryAgain,
  };
};

export default useDoTheBackup;
