import axios from 'axios';
import storage, { keyNames } from '../../../utils/storage';

const api = () => {
  const { get } = storage();
  const pivotal = axios.create({
    baseURL: 'https://www.pivotaltracker.com/services/v5',
    headers: {
      'X-TrackerToken': get(keyNames.tokens)?.pivotal,
    },
  });

  const endpoints = {
    get: {
      allProjects: '/projects',
      singleProject: (projectId) => `/projects/${projectId}`,
      allStoriesProject: (projectId) =>
        `/projects/${projectId}/stories?limit=3000`,
      singleStory: (projectId, storyId) =>
        `/projects/${projectId}/stories/${storyId}`,
      activityStory: (projectId, storyId) =>
        `/projects/${projectId}/stories/${storyId}/activity?limit=3000`,
      commentsStory: (projectId, storyId) =>
        `/projects/${projectId}/stories/${storyId}/comments`,
    },
  };

  return { pivotal, endpoints };
};

export default api;
