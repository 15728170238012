/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import Container from './components/Container';
import TopBar from './components/TopBar';
import CreateMR from './pages/CreateMR';
import Configurations from './pages/Config';
import History from './pages/History';
import BranchDiffs from './pages/BranchDiffs';
import QrCode from './pages/QrCode';
import PivotalBackup from './pages/PivotalBackup';
import { FetchedDataContext } from './context/fetchedData';
import storage, { keyNames } from './utils/storage';
import ChangeLogModal from './components/ChangeLogModal';
import { useTranslation } from 'react-i18next';

function App() {
  const { data, setData } = useContext(FetchedDataContext);
  const { i18n } = useTranslation();
  const { get } = storage();

  useEffect(() => {
    const { pivotal, gitlab, clickup } = get(keyNames.tokens) || {};
    const langSaved = get(keyNames.lang);
    if (!gitlab && (pivotal || clickup)) {
      setData((prev: any) => ({ ...prev, pageSelected: 102 }));
    }
    if (gitlab && !pivotal && !clickup) {
      setData((prev: any) => ({ ...prev, pageSelected: 101 }));
    }
    if (langSaved) {
      i18n.changeLanguage(langSaved);
    } else {
      const browserLang = navigator.language;
      switch (browserLang) {
        case 'pt-BR':
          i18n.changeLanguage('pt');
          break;
        case 'en-US':
          i18n.changeLanguage('pt');
          break;
        default:
          i18n.changeLanguage('en');
          break;
      }
    }
  }, []);

  return (
    <>
      <TopBar />
      <Container>
        {data.pageSelected === 100 ? (
          <CreateMR
            goToConfig={() =>
              setData((prev: any) => ({ ...prev, pageSelected: 300 }))
            }
          />
        ) : null}
        {data.pageSelected === 101 ? <BranchDiffs /> : null}
        {data.pageSelected === 200 ? <History /> : null}
        {data.pageSelected === 201 ? <QrCode /> : null}
        {data.pageSelected === 300 ? <Configurations /> : null}
        {data.pageSelected === 400 ? <PivotalBackup /> : null}
      </Container>
      <ChangeLogModal />
    </>
  );
}

export default App;
