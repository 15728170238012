/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import './bkp-pivotal.css';
import api from './api';
import storage, { keyNames } from '../../utils/storage';
import useDoTheBackup from '../../hooks/useDoTheBackup';
import { FaRepeat, FaPlay } from 'react-icons/fa6';

function PivotalBackup() {
  const { pivotal, endpoints } = api();
  const { get } = storage();

  const [token, setToken] = useState('');
  const [startTime, setStartTime] = useState('');

  useEffect(() => {
    const tokenSaved = get(keyNames.tokens)?.pivotal;
    if (tokenSaved) {
      setToken(tokenSaved);
    }
  }, []);

  const downloadData = (data) => {
    // Binary Large Object
    const blob = new Blob([JSON.stringify(data, null, 2)], {
      type: 'application/json',
    });
    const date = dayjs().format('DD-MM-YYYY_HH[h]mm');
    saveAs(blob, `pivotal_backup_${date}.json`);
  };

  const {
    started,
    isLoading,
    error,
    currentProject,
    projectProgress,
    doTheBackup,
    tryAgain,
  } = useDoTheBackup(pivotal, endpoints, downloadData);

  useEffect(() => {
    if (started === false) return;
    const time = new Date();
    setStartTime(time);
  }, [started]);

  return (
    <Box
      className="pivolazy"
      display="flex"
      justifyContent="center"
      alignItems="center"
      minWidth="100%"
      flexDirection="column"
    >
      {error && <Alert severity="error">{error}</Alert>}
      {!token ? (
        <Alert severity="error">
          Você deve definir a API Token do Pivotal Tracker nas configurações.
        </Alert>
      ) : null}
      <Typography
        variant="h2"
        marginBottom="5px"
        textAlign="center"
        fontWeight="900"
      >
        pivoLAZY
      </Typography>
      <Typography
        variant="body1"
        marginBottom="25px"
        textAlign="center"
      >
        Backup do Pivotal Tracker
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="row"
        width="100%"
        maxWidth="650px"
        gap="10px"
        flexWrap="wrap"
      >
        <TextField
          type="text"
          label="API Token Pivotal"
          fullWidth
          value={token}
          disabled
        />
        <Button
          id="start-button"
          color="primary"
          variant="contained"
          disabled={!token || started}
          onClick={() => doTheBackup()}
          startIcon={<FaPlay />}
        >
          {isLoading ? (
            <CircularProgress
              size={24}
              color="inherit"
            />
          ) : (
            'Começar'
          )}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          disabled={(!started && !error) || isLoading}
          onClick={() => tryAgain()}
          startIcon={<FaRepeat />}
        >
          Repetir
        </Button>
      </Box>

      {started && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={2}
          width="100%"
          maxWidth="650px"
        >
          {currentProject && (
            <Typography
              variant="body1"
              mr={2}
            >
              Coletando dados do projeto: {currentProject}
            </Typography>
          )}
          {startTime && (
            <Typography variant="caption">
              {`Iniciado às ${dayjs(startTime).format('HH:mm:ss')}`}
            </Typography>
          )}
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
          >
            <LinearProgress
              sx={{ width: '100%' }}
              variant="determinate"
              value={projectProgress}
              color={error ? 'error' : 'success'}
            />
            <Typography
              variant="body1"
              ml={2}
            >
              {projectProgress}%
            </Typography>
          </Box>
        </Box>
      )}

      {started && (
        <Box
          mt={4}
          width="100%"
          maxWidth="800px"
        >
          {!isLoading ? (
            <Typography
              variant="h6"
              textAlign="center"
            >
              Concluído!
            </Typography>
          ) : (
            <Typography
              variant="h6"
              textAlign="center"
            >
              Processando...
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

export default PivotalBackup;
